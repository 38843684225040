(function ( $ ) {
     $.fn.menuLink = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink    = $(this);
        var dropdown   = $('.dropdown');
        var head       = $('#head');
        var nav        = $('#head .nav');
        var opened     = false;

        function _init() {
            $( objLink ).click(function() {
                if($('#head .nav').hasClass('close')){
                    _openNavigation();
                }else{
                    _closeNavigation();
                }
            });
        }

        function _closeNavigation(){
            $(nav).addClass('close');
            $(objLink).find('i').addClass('icon-menu').removeClass('icon-close');
            $('body').removeClass('locked');
        }

        function _openNavigation(){
            $(nav).addClass('active').removeClass('close');
            $(objLink).find('i').removeClass('icon-menu').addClass('icon-close');
            $('body').addClass('locked');
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$("#head a.mobilNav").menuLink();
